<template>
  <div>
    <v-card-text class="px-0 py-0 black--text">
      I understand that by clicking below I will unlink my partner's account and
      disable information sharing in the application. I further understand that
      this does NOT revoke my overall consent to share protected health
      information with my partner,{{ '  ' }}
      <span class="px-0 py-0 black--text underline">
        and that if I want to revoke my overall consent to share protected
        health information with my partner, then I need to separately revoke
        that authorization in writing to my clinic.
      </span>
    </v-card-text>

    <div class="mt-6">
      <v-btn
        color="red darken-4 white--text"
        rounded
        block
        large
        @click="unlinkModal()"
      >
        Unlink Partner Account
      </v-btn>
    </div>
  </div>
</template>

<script>
import { api, auth, partner } from '../../sharedPlugin';

export default {
  computed: {
    ...auth.mapComputed(['user']),
    ...partner.mapComputed(['getPartner'])
  },
  methods: {
    ...partner.mapMethods(['setLocalPartner']),
    unlinkModal() {
      this.$modal.show({
        text: `You are about to disconnect your account from your partner.<br>Would you like to continue disconnecting from your partner?`,
        buttons: [
          {
            text: 'Cancel',
            color: 'grey black--text',
            onAction: () => {
              return;
            }
          },
          {
            text: 'Disconnect',
            color: 'primary white--text',
            onAction: async () => {
              return await this.unlinkSubmit();
            }
          }
        ]
      });
    },
    async unlinkSubmit() {
      let body = {
        pid: this.user.patientId,
        note:
          'Patient(' +
          this.user.fullName +
          ') Deleted a Partner(' +
          this.getPartner.name +
          ') on Prelude Connect',
        userId: this.user.userId
      };

      await api.PartnerConnect.unlinkPartner(
        this.getPartner.patientId,
        this.getPartner.isPartner
      )
        .then(async (res) => {
          await api.PartnerConnect.notes(body);

          this.setLocalPartner(null);
          this.$router.push('/my-partner');
        })
        .catch(() => {
          this.$store.commit('showSnackbar', {
            message: 'Unable to unlink partner',
            color: 'red'
          });
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>

<style scoped>
.theme--primary.v-card {
  color: '#1F6070';
}
.underline {
  text-decoration: underline;
}
</style>
